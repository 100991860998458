.navbar {
  background-color: #555;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px;
}

.menu-bar {
  margin-left: 4rem;
  font-size: 3rem;
  background: none;
  color: "#fff"
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  z-index: 1;
  background-color: #555;
  opacity: 1;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a,
.nav-text2 {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover,
.nav-text2:hover {
  background-color: #4cb138;
  cursor: pointer;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
  color: #fff;
  font-weight: 300;
}

.nav-menu-items span {
  margin-left: 16px;
}

@media only screen and (max-width: 600px){
	*{
		overflow-x: hidden;
	}
	.nav-menu {
    background-color: #555;
    width: 0;
  }
}